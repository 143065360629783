import React from "react";
import Input from "../input";
import emailjs from "emailjs-com";
import { useHistory } from "react-router-dom";

const Form = () => {
  const history = useHistory();

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_lx5cfc9",
        "template_9p7u3ym",
        e.target,
        "user_Wr2EJGpJuesz9IzemRp2K"
      )
      .then(
        (result) => {
          console.log(result.text);
          history.push("/SuccessPage");
        },
        (error) => {
          console.log(error.text);
          history.push("/ErrorPage");
        }
      );
  }

  return (
    <form id="contact-form" action="/" onSubmit={sendEmail}>
      <Input
        type={"text"}
        id={"name"}
        name={"name"}
        placeholder={"Name"}
        required={true}
      />
      <Input
        type={"email"}
        id={"email"}
        name={"email"}
        placeholder={"Email"}
        required={true}
      />
      <Input
        type={"textarea"}
        id={"message"}
        name={"message"}
        placeholder={"Message"}
        required={true}
      />
      <button type={"submit"}>Send</button>
    </form>
  );
};

export default Form;
