import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Form from "../../components/form";
import QuickContact from "../../components/QuickContact";
import Map from "../../components/map";

const Contact = () => {
  return (
    <div className="bg-grey section ">
      <div className="section-wrap section pt-120 pt-lg-80 pt-md-80 pt-sm-80 pt-xs-50 pb-120 pb-lg-80 pb-md-80 pb-sm-80 pb-xs-50">
        <Container>
          <Row>
            <Col lg={6} className={"mb-sm-45 mb-xs-45"}>
              <Row>
                <Col lg={10} className={"mb-45"}>
                  <div className="contact-form">
                    <h3>Let's Talk, Anytime.</h3>
                    <Form />
                  </div>
                </Col>

                <Col lg={10}>
                  <QuickContact
                    phone={"+1 437-688-5788"}
                    email={"danolaleye@gmail.com"}
                    location={"1298 Southwood Dr, Ottawa, ON, Canada"}
                  />
                </Col>
              </Row>
            </Col>

            <Col lg={6}>
              <Map
                // text={
                //   "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour lebmid"
                // }
                lat={45.3450606}
                long={-75.7832295}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Contact;
