import React from "react";
import { Link } from "react-router-dom";

const SuccessPage = () => {
  return (
    <div className="content-body section">
      <div className="section-wrap section error-404-wrap d-flex align-items-center bg-grey">
        <div className="container">
          <div className="row align-items-center">
            <div className="error-404-content col-md-6 col-12 order-2 order-md-1 text-center text-md-left">
              {/* <h1>Sent!</h1> */}
              <h3>
                Thank you for your message. I will get back to you shortly.
              </h3>
              <Link to={`${process.env.PUBLIC_URL + "/"}`}>Back To Home</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
